<template>
  <div
    class="relative sm:py-8 sm:px-16 sm:bg-white sm:mx-auto rounded-xl shadow-lg mt-3 py-5 px-5 extensions"
  >
    <div class="flex justify-between align-center">
      <h3 class="font-bold sm:text-xl text-base text-left">
        {{ title }}
      </h3>
      <a-menu mode="horizontal">
        <a-sub-menu>
          <a-button
            slot="title"
            type="primary"
            style="margin-bottom: 16px"
            @click="collapsed = !collapsed"
          >
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
          </a-button>
          <a-menu-item>
            <vue-excel-xlsx
              :loading="loadingReport"
              :data="downloadExtensions"
              :columns="columnsExcel"
              file-name="Tiempo_respuesta-prorroga_quejas"
              sheetName="Listado"
            >
              <span class="mx-2">Descargar vista actual XLSX</span>
            </vue-excel-xlsx></a-menu-item
          >
          <a-menu-item @click="onGetAllComplaints(false)"
            ><vue-excel-xlsx
              :async="true"
              :loading="loadingReport"
              :data="downloadAllExtensions"
              :columns="columnsExcel"
              file-type="xlsx"
              file-name="Tiempo_respuesta-prorroga_quejas(Completo)"
              sheetName="Listado"
            >
              <span class="mx-2">Descargar reporte completo XLSX</span>
            </vue-excel-xlsx></a-menu-item
          >
          <a-menu-item @click="onGetAllComplaints(true)">
            <vue-excel-xlsx
              :async="true"
              :loading="loadingReport"
              :data="downloadAllExtensionsCsv"
              :columns="columnsExcel"
              file-name="Tiempo_respuesta-prorroga_quejas"
              sheetName="Listado"
              file-type="csv"
            >
              <span class="mx-2">Descargar reporte completo CSV</span>
            </vue-excel-xlsx></a-menu-item
          >
        </a-sub-menu>
      </a-menu>
    </div>
    <a-table
      :columns="columns"
      :data-source="tdata"
      :pagination="false"
      rowKey="codigo_queja"
      :scroll="{ x: 'calc(640px + 50%)' }"
      class="sm:mt-5 border rounded-xl p-1"
    >
      <div slot="fecha_creacion" slot-scope="text, record" class="text-center">
        {{ record.fecha_creacion }}
      </div>
      <div slot="fecha_cierre" slot-scope="text, record" class="text-center">
        {{ record.fecha_cierre }}
      </div>
      <div slot="nombre_entidad" slot-scope="text, record">
        {{ record.nombre_entidad | capitalize }}
      </div>
      <div
        slot="queja_expres"
        slot-scope="text, record"
        class="text-center font-bold"
      >
        {{ record.queja_expres }}
      </div>
      <div
        slot="totalTime"
        slot-scope="text, record"
        class="text-blue-800 sm:text-sm text-center font-bold sm:my-1"
      >
        {{
          (record.total_segundos / 86400).toFixed(0) > 0
            ? (record.total_segundos / 86400).toFixed(0) + " días"
            : (record.total_segundos / 3600).toFixed(0) + " horas"
        }}
      </div>
      <div
        slot="extension"
        slot-scope="text, record"
        class="sm:text-sm text-center sm:my-1 font-bold"
        :class="record.prorroga_queja > 1 ? 'text-red-600' : 'text-blue-800 '"
      >
        {{ record.prorroga_queja * 15 }} días
      </div>
    </a-table>
    <div class="mt-4 flex justify-end">
      <a-pagination
        v-model="newPage"
        :page-size="pageSize"
        :page-size-options="pageSizeOptions"
        :total="count"
        show-less-items
        size="large"
      />
    </div>
  </div>
</template>
<script>
import capitalize from "@/utils/checkForms.js";
const columns = [
  {
    title: "Código de la Queja",
    dataIndex: "codigo_queja",
    key: "codigo_queja",
  },
  {
    title: "Fecha de creación",
    dataIndex: "fecha_creacion",
    key: "fecha_creacion",
    scopedSlots: { customRender: "fecha_creacion" },
  },
  {
    title: "Fecha de cierre",
    dataIndex: "fecha_cierre",
    key: "fecha_cierre",
    scopedSlots: { customRender: "fecha_cierre" },
  },
  {
    title: "Entidad",
    dataIndex: "nombre_entidad",
    key: "nombre_entidad",
    width: "20%",
    scopedSlots: { customRender: "nombre_entidad" },
  },
  {
    title: "Queja Express",
    dataIndex: "queja_expres",
    key: "queja_expres",
    width: "10%",
    scopedSlots: { customRender: "queja_expres" },
  },
  {
    title: "Tiempo Total",
    dataIndex: "total_segundos",
    key: "total_segundos",
    width: "10%",
    scopedSlots: { customRender: "totalTime" },
  },
  {
    title: "Prórroga",
    dataIndex: "prorroga_queja",
    key: "prorroga_queja",
    width: "10%",
    scopedSlots: { customRender: "extension" },
  },
];

export default {
  props: {
    title: { type: String },
    tdata: { type: [], required: true },
    page: { type: Number, default: 1 },
    count: { type: Number, default: 0 },
    downloadExtensions: { type: [], required: false },
    actualQuery: { type: Object, require: false },
  },
  mounted() {
    this.newPage = this.page;
  },

  data() {
    return {
      collapsed: false,
      columns,
      columnsExcel: [
        { label: "Radicado", field: "Radicado" },
        { label: "Fecha de creación", field: "Fecha de creación" },
        { label: "Fecha de cierre", field: "Fecha de cierre" },
        { label: "Entidad", field: "Entidad" },
        { label: "Queja Extress", field: "Queja Extress" },
        { label: "Prórroga", field: "Prórroga" },
        { label: "Tiempo total", field: "Tiempo total" },
      ],
      pageSize: 10,
      newPage: 1,
      pageSizeOptions: ["50", "100", "150", "200", "250"],

      loading: false,
      loadingReport: false,
      pageSizeDownload: 10000,
      downloadAllExtensions: [],
      downloadAllExtensionsCsv: [],
    };
  },
  methods: {
    pageChange() {
      this.$emit("onPageChange", this.newPage);
    },
    onGetAllComplaints(isCsv = false) {
      this.loadingReport = true;
      // Se realiza una primera petición para determinar el total de registros a descargar
      let pages_all = Math.trunc(this.count / this.pageSizeDownload);
      let mod = this.count % this.pageSizeDownload > 0 ? 1 : 0;
      pages_all += mod;
      const promises = [];
      let dataResults = [];
      this.downloadAllExtensions = [];
      this.downloadAllExtensionsCsv = [];

      for (let i = 0; i < pages_all; i++) {
        const bodyComplaint = {
          filter_model: this.actualQuery?.filter_model,
          offset: i,
        };
        promises.push(this.$api.searchComplaints(bodyComplaint));
      }
      // Ejecutamos todas las peticiones.
      Promise.allSettled(promises)
        .then((response) => {
          for (let i = 0; i < pages_all; i++) {
            const complaint_search = response[i]?.value?.data?.results?.map(
              (item) => {
                return {
                  Radicado: item.codigo_queja,
                  "Fecha de creación": item.fecha_creacion,
                  "Fecha de cierre": item.fecha_cierre,
                  Entidad: capitalize(item.nombre_entidad),
                  "Queja Extress": item.queja_expres,
                  Prórroga: item.prorroga_queja ? item.prorroga_queja : 0,
                  "Tiempo total":
                    (item.total_segundos / 86400).toFixed(0) > 0
                      ? (item.total_segundos / 86400).toFixed(0) + " días"
                      : (item.total_segundos / 3600).toFixed(0) + " horas",
                };
              }
            );
            dataResults = [...dataResults, ...complaint_search];
          }

          this.loadingReport = false;
          if (isCsv) this.downloadAllExtensionsCsv = dataResults;
          else this.downloadAllExtensions = dataResults;
        })
        .catch((error) => {
          console.error(error);
          this.loadingReport = false;
        });
    },
  },
  watch: {
    newPage: function (newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      } else {
        this.pageChange();
      }
    },
  },
};
</script>
<style>
.extensions .ant-table-thead > tr > th {
  font-weight: bold;
  color: #6b7280;
  text-align: center;
}
</style>