<template>
  <section
    class="
      relative
      sm:py-8 sm:px-16 sm:bg-white sm:mx-auto
      rounded-xl
      shadow-lg
      py-5
      px-5
    "
  >
    <h3 class="font-bold sm:text-2xl sm:text-3xl text-lg text-left">
      {{ title }}
    </h3>
    <apexchart
      type="line"
      height="400"
      :options="chartOptions"
      :series="series"
      class="mt-6"
    ></apexchart>
  </section>
</template>
<script>
export default {
  props: {
    title: String,
    dataCurves: [],
    datax: [],
    totals: [],
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: "100%",
          type: "line",
          zoom: {
            enabled: false,
          },
        },
         noData: {
          text: 'No hay datos para graficar',
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "24px",
            fontFamily: undefined,
          },
        },
        plotOptions: {
          bar: {
            horizontal: undefined,
            borderRadius: 20,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          offsetX: 500,
          fontSize: "24px",
          formatter: function (val, opts) {
            const reducer = (accumulator, curr) => accumulator + curr;
            let total =
              opts.w.config.series[opts.seriesIndex].data.reduce(reducer);

            return val + " " + total;
          },
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  mounted() {
    this.series = this.dataCurves;
    this.chartOptions.xaxis.categories = this.datax;
  },
};
</script>
