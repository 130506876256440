<template>
  <div
    class="relative sm:py-8 sm:px-10 sm:bg-white sm:mx-auto rounded-xl shadow-lg py-2 px-3 recurrent-petitioners"
  >
    <div class="flex justify-between align-center">
      <h3 class="font-bold sm:text-xl text-base text-left">
        {{ title }}
      </h3>

      <a-menu mode="horizontal">
        <a-sub-menu>
          <a-button
            slot="title"
            type="primary"
            style="margin-bottom: 16px"
            @click="collapsed = !collapsed"
          >
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
          </a-button>
          <a-menu-item>
            <vue-excel-xlsx
              :data="downloadRecurrentPetitioners"
              :columns="columnsExcel"
              file-name="Listado-peticionarios-recurrentes"
              sheetName="Listado-peticionarios"
            >
              <span class="mx-2">Descargar vista actual XLSX</span>
            </vue-excel-xlsx></a-menu-item
          >
            <a-menu-item
            ><vue-excel-xlsx
              :data="downloadAllRecurrentPetitioners"
              :columns="columnsExcel"
              file-name="Listado-peticionarios-recurrentes(Completo)"
              sheetName="Listado-peticionarios"
            >
              <span class="mx-2">Descargar reporte completo XLSX</span>
            </vue-excel-xlsx></a-menu-item
          >
          <a-menu-item>
            <vue-excel-xlsx
              :data="downloadAllRecurrentPetitioners"
              file-name="Listado-peticionarios-recurrentes"
              sheetName="Listado-peticionarios"
              :file-type="'csv'"
              :columns="columnsExcel"
            >
              <span class="mx-2">Descargar reporte completo CSV</span>
            </vue-excel-xlsx></a-menu-item
          >
        
        </a-sub-menu>
      </a-menu>
    </div>
    <a-table
      :columns="columns"
      :data-source="tdata"
      :pagination="false"
      rowKey="index"
      :scroll="{ x: 'calc(640px + 50%)' }"
      class="sm:mt-5 border rounded-xl p-1"
    >
      <div slot="username" slot-scope="text, record">
        {{ record.username | capitalize }}
      </div>
      <span
        slot="complaints"
        slot-scope="text, record"
        class="text-gray-400 sm:text-sm text-left sm:my-1"
      >
        {{ record.complaints }} Quejas radicadas
        <a-progress
          :percent="record.complaintsSolved"
          :strokeWidth="9"
          strokeColor="blue"
          strokeLinecap="square"
        />
      </span>
      <a-table
          slot="expandedRowRender"
          slot-scope="record"
          :columns="innerTable"
          :data-source="record.details"
          :pagination="false"
      >
      </a-table>
    </a-table>
    <div class="mt-4 flex justify-end">
      <a-pagination
        v-model="newPage"
        :page-size="pageSize"
        :page-size-options="pageSizeOptions"
        :total="count"
        show-less-items
        size="large"
      />
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "",
    dataIndex: "index",
    key: "index",
    width: "2%",
  },
  {
    title: "Tipo de Identificación",
    dataIndex: "typeId",
  },
  {
    title: "Número de Identifiación",
    dataIndex: "identification",
  },
  {
    title: "Nombre de Usuario",
    dataIndex: "username",
    key: "username",
    scopedSlots: { customRender: "username" },
  },
  {
    title: "Total Quejas",
    dataIndex: "complaints",
    scopedSlots: { customRender: "complaints" },
  },
];
const innerTable = [
  {
    title: "Radicado",
    dataIndex: "_field_1",
    key: "_field_1",
  },
  {
    title: "Producto",
    dataIndex: "_field_2",
    key: "_field_2",
  },
  {
    title: "Motivo",
    dataIndex: "_field_3",
    key: "_field_3",
  },
]
export default {
  props: {
    title: { type: String },
    tdata: { type: [], required: true },
    page: { type: Number, default: 1 },
    count: { type: Number, default: 0 },
    downloadRecurrentPetitioners: { type: [], required: false },
    downloadAllRecurrentPetitioners: { type: [], required: false },
  },
  mounted() {
    this.newPage = this.page;
  },
  data() {
    return {
      collapsed: false,
      columns,
      innerTable,
      columnsExcel: [
        {
          label: "Tipo de identificación",
          field: "Tipo de identificación",
        },
        { label: "Número", field: "Número" },
        { label: "Nombre", field: "Nombre" },
        { label: "Radicado", field: "Radicado" },
        { label: "Producto", field: "Producto" },
        { label: "Motivo", field: "Motivo" },
        { label: "Total de quejas", field: "Total de quejas" },
      ],
      pageSize: 10,
      newPage: 1,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  methods: {
    pageChange() {
      this.$emit("onPageChange", this.newPage);
    },
  },
  watch: {
    newPage: function (newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      } else {
        this.pageChange();
      }
    },
  },
};
</script>
<style>
.recurrent-petitioners .ant-table-thead > tr > th {
  font-weight: bold;
  color: #6b7280;
}
</style>
