<template>
  <section
    class="
      relative
      bg-white
      sm:text-left
      shadow-xl
      rounded-xl
      sm:w-full sm:p-10 sm:px-14
      p-5
      sm:mb-0
      mb-3
    "
  >
    <h3 class="sm:text-xl font-bold text-base">
      Ranking de Satisfacción Uso de la Plataforma Smart Supervision
    </h3>

    <div class="my-6">
      <a-icon
        class="text-blue text-5xl xl:text-6xl float-left left-3 sm:mr-5 mr-0"
        type="star"
        theme="filled"
      />
      <span class="text-gray-400 lg:text-md text-sm"
        >TOTAL DE CALIFICACIONES REGISTRADAS</span
      >
      <h4 class="lg:text-2xl text-lg font-bold">{{ total | number("0,0") }}</h4>
    </div>

    <br />
    <ul class="sm:flex sm:flex-col gap-3">
      <li v-for="rank in ranking" :key="rank.name" class="flex gap-2 mb-4">
        <div class="float-left w-1/4">
          <a-icon
            class="text-blue  text-3xl 2xl:text-5xl mr-2 "
            type="star"
            theme="filled"
          />
          <strong class="text-blue-900 text-xl sm:text-2xl">{{
            rank.name
          }}</strong>
        </div>

        <span
          class="
            w-full
            text-gray-400
            w-0
            sm:text-lg sm:text-left sm:ml-5
            ml-0
            text-left
          "
        >
          {{ rank.count }}
          <a-progress
            :percent="parseInt(rank.percent)"
            :strokeWidth="10"
            strokeColor="rgb(0, 72, 132)"
            strokeLinecap="square"
            class="w-full"
          />
        </span>
      </li>
    </ul>
  </section>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      required: true,
    },
    ranking: {
      type: [],
      required: true,
      default: () => {
        return;
      },
    },
  },
  data() {
    return {};
  },
};
</script>